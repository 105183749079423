// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：库存.仓库模块相关接口
// 开始时间：2021-04-27
// 开发人员：刘巍骏
// 最后修改：2021-04-27
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //出入库单相关操作
  addDepotMainExport(data) { //出入库生成单据
    return request({
      method: 'POST',
      url: '/orderCommon/addDepotMainExport',
      data
    })
  },
  exportDepotMainList(data) { //出入库单据例表
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportDepotMainList',
      data
    })
  },
  addDepotMain(data) { //新增出入库单
    return request({
      method: 'POST',
      url: '/stock/addDepotMain',
      data
    })
  },
  updateDepotMain(data) { //修改出入库单(基本信息)
    return request({
      method: 'POST',
      url: '/stock/updateDepotMain',
      data
    })
  },
  updateDepotMainProNum(data) { //修改出入库单(货品信息)
    return request({
      method: 'POST',
      url: '/stock/updateDepotMainProNum',
      data
    })
  },
  deleteDepotMain(data) { //作废出入库单
    return request({
      method: 'POST',
      url: '/stock/deleteDepotMain',
      data
    })
  },
  updateDepotMainSubmit(data) { //出入库单提交审核
    return request({
      method: 'POST',
      url: '/stock/updateDepotMainSubmit',
      data
    })
  },
  updateStatusNo(data) { //出入库单驳回
    return request({
      method: 'POST',
      url: '/stock/updateStatusNo',
      data
    })
  },
  updateStatusYes(data) { //出入库单通过审核
    return request({
      method: 'POST',
      url: '/stock/updateStatusYes',
      data
    })
  },
  againstDepotBill(data) { //出入库单反审操作
    return request({
      method: 'POST',
      url: '/stock/againstDepotBill',
      data
    })
  },
  findDepotMainByCondition(data) { //条件查询出入库单
    return request({
      method: 'POST',
      url: '/stock/findDepotMainByCondition',
      data
    })
  },
  findDepotMainDetail(data) { //查询出入库单详情
    return request({
      method: 'POST',
      url: '/stock/findDepotMainDetail',
      data
    })
  },
  updateDepotMainSubmit(data) { //提交审核处理
    return request({
      method: 'POST',
      url: '/stock/updateDepotMainSubmit',
      data
    })
  },
  updateStatusYes(data) { //通过审核处理
    return request({
      method: 'POST',
      url: '/stock/updateStatusYes',
      data
    })
  },
  updateStatusNo(data) { //驳回单据
    return request({
      method: 'POST',
      url: '/stock/updateStatusNo',
      data
    })
  },
  updateDepotMainProNumPartner(data) { //修改出入库单货品合作伙伴物料编号
    return request({
      method: 'POST',
      url: '/stock/updateDepotMainProNumPartner',
      data
    })
  },
  updateDepotMainProNumRealityTotalCostPrice(data) { //修改出入库单货品实际金额(采购)
    return request({
      method: 'POST',
      url: '/stock/updateDepotMainProNumRealityTotalCostPrice',
      data
    })
  },
  updateDepotMainProNumRealityTotalPrice(data) { //修改出入库单货品实际金额(销售)
    return request({
      method: 'POST',
      url: '/stock/updateDepotMainProNumRealityTotalPrice',
      data
    })
  },
  deleteDepotMain(data) { //作废单据
    return request({
      method: 'POST',
      url: '/stock/deleteDepotMain',
      data
    })
  },
  generateAllotBillsToTemp(data) { //根据出入库单单据id生成调拨单到临时仓库
    return request({
      method: 'POST',
      url: '/stock/generateAllotBillsToTemp',
      data
    })
  },
  generateGetMaterialPourFlash(data) { //根据出入库单单据id生成倒冲物料领料单
    return request({
      method: 'POST',
      url: '/stock/generateGetMaterialPourFlash',
      data
    })
  },
  findDepotProByRelvanceId(data){//根据多个关联单据查询出入库单相关单据
    return request({
      method: 'POST',
      url: '/stock/findDepotProByRelvanceId',
      data
    })
  },

  //出入库单关联单据查询
  findDepotOriginBill(data) { //查询出库单源单单据
    return request({
      method: 'POST',
      url: '/stock/findDepotOriginBill',
      data
    })
  },
  findDepotMainQuality(data) { //查询出入库质检单单据
    return request({
      method: 'POST',
      url: '/stock/findDepotMainQuality',
      data
    })
  },
  findDepotMainOutIn(data) { //查询出入库退货/归还出入库单据
    return request({
      method: 'POST',
      url: '/stock/findDepotMainOutIn',
      data
    })
  },
  findCostInvoice(data) { //出入库发票单据
    return request({
      method: 'POST',
      url: '/stock/findCostInvoice',
      data
    })
  },
  findCostMainOut(data) { //查询出入库退款单单据
    return request({
      method: 'POST',
      url: '/stock/findCostMainOut',
      data
    })
  },
  findDepotProByproductId(data) { //根据货品id查询相关交易信息
    return request({
      method: 'POST',
      url: '/stock/findDepotProByproductId',
      data
    })
  },

  //货品库存相关
  findProNumByHouseIdAndProId(data) { //根据货品id和仓库id查询货品库存
    return request({
      method: 'POST',
      url: '/stock/findProNumByHouseIdAndProId',
      data
    })
  },
  judgeMaterialIsAdequate(data) { //判断倒冲物料是否充足(临时仓)
    return request({
      method: 'POST',
      url: '/stock/judgeMaterialIsAdequate',
      data
    })
  },
  judgeMaterialIsGenterial(data) { //判断出入库单关联的源单倒冲物料是否已经生成领料出库单
    return request({
      method: 'POST',
      url: '/stock/judgeMaterialIsGenterial',
      data
    })
  },
  productionMaterialReturn(data) { //生产详细中生成生产退料单接口
    return request({
      method: 'POST',
      url: '/stock/productionMaterialReturn',
      data
    })
  },
  ProductionReplenishment(data) { //生产详细中生成生产补料单接口
    return request({
      method: 'POST',
      url: '/stock/ProductionReplenishment',
      data
    })
  },
  finishedGoodsReceiptDoc(data) { //生产详细中生成生产补料单接口
    return request({
      method: 'POST',
      url: '/stock/finishedGoodsReceiptDoc',
      data
    })
  },
}
