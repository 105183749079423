// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：销售.单据模块相关接口
// 开始时间：2021-03-13
// 开发人员：刘巍骏
// 最后修改：2021-03-13
// 备注说明：如需修改请联系开发人员

import {request} from "../request.js";
import qs from 'qs';

export default{
  // 单据公共接口相关
  findCodeByFormatStr(data){//根据单据类型id生成单据编号
    return request({
      method:'POST',
      url:'/orderCommon/findCodeByFormatStr',
      data
    })
  },
  findBatchCodeByFormatStr(data){//根据单据类型id生成批次号
    return request({
      method:'POST',
      url:'/orderCommon/findBatchCodeByFormatStr',
      data
    })
  },
  updateOrderFile(data){//修改单据附件信息
    return request({
      method:'POST',
      url:'/orderCommon/updateOrderFile',
      data
    })
  },
  updateCostBillsInvoice(data){//修改单据发票信息
    return request({
      method:'POST',
      url:'/orderCommon/updateCostBillsInvoice',
      data
    })
  },
  updateOrderRemark(data){//修改单据备注信息
    return request({
      method:'POST',
      url:'/orderCommon/updateOrderRemark',
      data
    })
  },
  updateOrderTransport(data){//修改单据物流信息
    return request({
      method:'POST',
      url:'/orderCommon/updateOrderTransport',
      data
    })
  },
  updateSysNodeCustomValue(data){//修改单据自定义信息(基本信息)
    return request({
      method:'POST',
      url:'/orderCommon/updateSysNodeCustomValue',
      data
    })
  },
  copeFileToEmailTemp(data){//将单据文件拷贝到邮件临时文件夹
    return request({
      method:'POST',
      url:'/orderCommon/copeFileToEmailTemp',
      data
    })
  },

  //报价单
  getNodePowerData(data){//获取节点相关权限信息
    return request({
      method:'POST',
      url:'/sales/getStatusOtherInfo',
      data
    })
  },
  getClassifyDataById(data){//根据客户分类id查询客户数据
    return request({
      method:'POST',
      url:'/sales/getCustomerByDialog',
      data
    })
  },
  addSaleOrderMain(data){//新增销售单据
    return request({
      method:'POST',
      url:'/sales/addSaleOrderMain',
      data
    })
  },
  updateSaleOrderMain(data){//修改销售单据(基本信息)
    return request({
      method:'POST',
      url:'/sales/updateSaleOrderMain',
      data
    })
  },
  updateOrderProduct(data){//修改销售单据(货品信息)
    return request({
      method:'POST',
      url:'/sales/updateOrderProduct',
      data
    })
  },
  updateOrderSubmit(data){//提交审核处理
    return request({
      method:'POST',
      url:'/sales/updateOrderSubmit',
      data
    })
  },
  updateOrderStatusYes(data){//销售单审核通过操作
    return request({
      method:'POST',
      url:'/sales/updateOrderStatusYes',
      data
    })
  },
  updateOrderStatusNo(data){//驳回操作
    return request({
      method:'POST',
      url:'/sales/updateOrderStatusNo',
      data
    })
  },
  againstOrderBill(data){//单据反审操作
    return request({
      method:'POST',
      url:'/sales/againstOrderBill',
      data
    })
  },
  deleteSaleOrderMain(data){//作废单据
    return request({
      method:'POST',
      url:'/sales/deleteSaleOrderMain',
      data
    })
  },
  findSaleOrderMainByCondition(data){//根据条件查询销售单据数据(列表页)
    return request({
      method:'POST',
      url:'/sales/findSaleOrderMainByCondition',
      data
    })
  },
  findSaleOrderProListByIds(data){//根据多个销售订单id查询货品明细列表
    return request({
      method:'POST',
      url:'/sales/findSaleOrderProListByIds',
      data
    })
  },
  findSalesOrderMainDetail(data){//根据销售单id查询单据详情
    return request({
      method:'POST',
      url:'/sales/findSalesOrderMainDetail',
      data
    })
  },
  generateOrderExport(data){//销售生成单据
    return request({
      method:'POST',
      url:'/orderCommon/GenerateOrderExport',
      data
    })
  },
  exportSaleOrderMainList(data){//销售生成单据列表
    return request({
      method:'POST',
      url:'/orderCommon/billsExport/exportSaleOrderMainList',
      data
    })
  },


  //销售单关联单据查询
  findSaleOriginbill(data){//查询销售源单单据
    return request({
      method:'POST',
      url:'/sales/findSaleOriginbill',
      data
    })
  },
  findOrderMain(data){//查询报价单转换订单单据
    return request({
      method:'POST',
      url:'/sales/findOrderMain',
      data
    })
  },
  findOrderPurchaseMain(data){//根据销售单id查询订单采购单据
    return request({
      method:'POST',
      url:'/sales/findOrderPurchaseMain',
      data
    })
  },
  findOrderMesMain(data){//根据销售单id查询订单生产单据
    return request({
      method:'POST',
      url:'/sales/findOrderMesMain',
      data
    })
  },
  findOrderDepotMainOut(data){//根据销售单id查询出库单列表
    return request({
      method:'POST',
      url:'/sales/findOrderDepotMainOut',
      data
    })
  },
  findOrderDepotMainRetun(data){//根据销售单id查询出库单列表
    return request({
      method:'POST',
      url:'/sales/findOrderDepotMainRetun',
      data
    })
  },
  findOrderCostMainIn(data){//根据销售单id查询订单收款单据
    return request({
      method:'POST',
      url:'/sales/findOrderCostMainIn',
      data
    })
  },
  findOrderInvoiceMain(data){//根据销售单id订单发票单据
    return request({
      method:'POST',
      url:'/sales/findOrderInvoiceMain',
      data
    })
  },
  findOrderCostCheckIn(data){//根据销售单id查询订单对账单据
    return request({
      method:'POST',
      url:'/sales/findOrderCostCheckIn',
      data
    })
  },
  offerSaleOrderMain(data){//根据货品id查询交易记录（销售模块）
    return request({
      method:'POST',
      url:'/sales/offerSaleOrderMain',
      data
    })
  },
}
