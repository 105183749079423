// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：库存.采购模块相关接口
// 开始时间：2021-05-13
// 开发人员：刘巍骏
// 最后修改：2021-05-13
// 备注说明：如需修改请联系开发人员

import {request} from "../request.js";
import qs from 'qs';

export default{
  //采购单相关操作
  addPurchaseMainExport(data){//采购单生成单据
    return request({
      method:'POST',
      url:'/orderCommon/addPurchaseMainExport',
      data
    })
  },
  exportPurchaseList(data){//采购单单据例表
    return request({
      method:'POST',
      url:'/orderCommon/billsExport/exportPurchaseList',
      data
    })
  },
  addPurchaseOrderMain(data){//新增采购单
    return request({
      method:'POST',
      url:'/stock/addPurchaseOrderMain',
      data
    })
  },
  addPurchaseOrderMainByMes(data){//生产计划单生成采购申请单(按供应商生成)
    return request({
      method:'POST',
      url:'/stock/addPurchaseOrderMainByMes',
      data
    })
  },
  addPurchaseApplyForByMrp(data){//根据mrp计算结果添加采购申请单
    return request({
      method:'POST',
      url:'/stock/addPurchaseApplyForByMrp',
      data
    })
  },
  updatePurchaseMain(data){//修改采购单(基本信息)
    return request({
      method:'POST',
      url:'/stock/updatePurchaseMain',
      data
    })
  },
  updateOrderProduct(data){//修改采购单(货品信息)
    return request({
      method:'POST',
      url:'/stock/updateOrderProduct',
      data
    })
  },
  findPurchaseMainByCondition(data){//条件查询采购单
    return request({
      method:'POST',
      url:'/stock/findPurchaseMainByCondition',
      data
    })
  },
  offerPurchaseMain(data){//条件查询采购单相关交易信息
    return request({
      method:'POST',
      url:'/stock/offerPurchaseMain',
      data
    })
  },
  deletePurchaseMain(data){//作废采购单
    return request({
      method:'POST',
      url:'/stock/deletePurchaseMain',
      data
    })
  },
  updatePurchaseMainStatusNo(data){//驳回采购单
    return request({
      method:'POST',
      url:'/stock/updatePurchaseMainStatusNo',
      data
    })
  },
  updatePurchaseMainSubmit(data){//提交采购单
    return request({
      method:'POST',
      url:'/stock/updatePurchaseMainSubmit',
      data
    })
  },
  updatePurchaseMainStatusYes(data){//审核采购单
    return request({
      method:'POST',
      url:'/stock/updatePurchaseMainStatusYes',
      data
    })
  },
  againstPurchaseBill(data){//单据反审操作
    return request({
      method:'POST',
      url:'/stock/againstPurchaseBill',
      data
    })
  },
  findPurchaseMainDetail(data){//根据采购单id查询采购单详情
    return request({
      method:'POST',
      url:'/stock/findPurchaseMainDetail',
      data
    })
  },


  //采购单关联单据查询
  findPurchaseOriginBill(data){//查询采购单源单单据
    return request({
      method:'POST',
      url:'/stock/findPurchaseOriginBill',
      data
    })
  },
  findPurchaseOrderMain(data){//根据采购单id查询采购申请单转换订单单据
    return request({
      method:'POST',
      url:'/stock/findPurchaseOrderMain',
      data
    })
  },
  findPurchaseCostMain(data){//根据采购单id查询采购订单付款单据
    return request({
      method:'POST',
      url:'/stock/findPurchaseCostMain',
      data
    })
  },
  findOrderCostCheckOut(data){//根据采购单id查询采购订单付款单据
    return request({
      method:'POST',
      url:'/stock/findOrderCostCheckOut',
      data
    })
  },
  findPurchaseEnquiryMain(data){//根据采购单id查询采购订单询价单据
    return request({
      method:'POST',
      url:'/stock/findPurchaseEnquiryMain',
      data
    })
  },
  findPurchaseInvoiceMain(data){//根据采购单id查询采购订单发票单据
    return request({
      method:'POST',
      url:'/stock/findPurchaseInvoiceMain',
      data
    })
  },
  findPurchaseDepotMainIn(data){//根据采购单id查询采购订单入库单据.
    return request({
      method:'POST',
      url:'/stock/findPurchaseDepotMainIn',
      data
    })
  },
  findPurchaseDepotMainReturn(data){//根据采购单id查询采购订单退货单据
    return request({
      method:'POST',
      url:'/stock/findPurchaseDepotMainReturn',
      data
    })
  },
  generatePurchaseRequisition(data){//根据生产详细中生成采购申请单
    return request({
      method:'POST',
      url:'/stock/generatePurchaseRequisition',
      data
    })
  },
}
