// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：资金.往来账(收付款)相关请求
// 开始时间：2021-05-06
// 开发人员：刘巍骏
// 最后修改：2021-05-06
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //收付款单相关
  addCostBillsMain(data) { //新增收付款单
    return request({
      method: 'POST',
      url: '/cost/addCostBillsMain',
      data,
    })
  },
  updateCostBillsMain(data) { //修改收付款单(基本信息)
    return request({
      method: 'POST',
      url: '/cost/updateCostBillsMain',
      data,
    })
  },
  updateCostBillsSubItem(data) { //修改收付款单(明细信息)
    return request({
      method: 'POST',
      url: '/cost/updateCostBillsSubItem',
      data,
    })
  },
  addCostBillsMainExport(data){//收付款生成单据
    return request({
      method:'POST',
      url:'/orderCommon/addCostBillsMainExport',
      data
    })
  },
  findCostBillsMainByCondition(data) { //根据条件查询收付款单据信息
    return request({
      method: 'POST',
      url: '/cost/findCostBillsMainByCondition',
      data
    })
  },
  findCostBillsMainDetail(data) { //查询收付款单详情
    return request({
      method: 'POST',
      url: '/cost/findCostBillsMainDetail',
      data
    })
  },
  deleteCostBillsMain(data) { //作废收付款单
    return request({
      method: 'POST',
      url: '/cost/deleteCostBillsMain',
      data
    })
  },
  updateCostBillsMainSubmit(data) { //提交审核收付款单处理
    return request({
      method: 'POST',
      url: '/cost/updateCostBillsMainSubmit',
      data
    })
  },
  updateCostBillsMainStatusYes(data) { //审核通过收付款单
    return request({
      method: 'POST',
      url: '/cost/updateCostBillsMainStatusYes',
      data
    })
  },
  updateCostBillsMainStatusNo(data) { //驳回收款单
    return request({
      method: 'POST',
      url: '/cost/updateCostBillsMainStatusNo',
      data
    })
  },
  againstCostMainBill(data) { //收付款单反审操作
    return request({
      method: 'POST',
      url: '/cost/againstCostMainBill',
      data
    })
  },
  findCostBillsAndSubItemByIds(data) { //根据关联单据id查询单据收付款信息
    return request({
      method: 'POST',
      url: '/cost/findCostBillsAndSubItemByIds',
      data
    })
  },
  findCostBillSubitemByRelvanceIds(data) { //根据多个关联单据id查询收付款单明细信息
    return request({
      method: 'POST',
      url: '/cost/findCostBillSubitemByRelvanceIds',
      data
    })
  },


  //报销单相关
  findBillsExpenseByCondition(data) { //根据多条件查询列表数据
    return request({
      method: 'POST',
      url: '/cost/findBillsExpenseByCondition',
      data
    })
  },
  addBillsExpense(data) { //添加报销单数据
    return request({
      method: 'POST',
      url: '/cost/addBillsExpense',
      data
    })
  },
  updateBillsExpense(data) { //修改报销单数据(基本信息)
    return request({
      method: 'POST',
      url: '/cost/updateBillsExpense',
      data
    })
  },
  updateBillsExpenseSubItem(data) { //修改报销单数据(明细信息)
    return request({
      method: 'POST',
      url: '/cost/updateBillsExpenseSubItem',
      data
    })
  },
  findBillsExpenseDetail(data) { //查询报销单详情
    return request({
      method: 'POST',
      url: '/cost/findBillsExpenseDetail',
      data
    })
  },
  deleteBillsExpense(data) { //作废报销单
    return request({
      method: 'POST',
      url: '/cost/deleteBillsExpense',
      data
    })
  },
  updateBillsExpenseStatusNo(data) { //审核驳回报销单
    return request({
      method: 'POST',
      url: '/cost/updateBillsExpenseStatusNo',
      data
    })
  },
  updateBillsExpenseStatusYes(data) { //审核通过报销单
    return request({
      method: 'POST',
      url: '/cost/updateBillsExpenseStatusYes',
      data
    })
  },
  updateBillsExpenseSubmit(data) { //提交审核处理
    return request({
      method: 'POST',
      url: '/cost/updateBillsExpenseSubmit',
      data
    })
  },
  againstExpenseBill(data) { //费用单据反审操作
    return request({
      method: 'POST',
      url: '/cost/againstExpenseBill',
      data
    })
  },
  findExpenseCostMain(data) { //查询报销单据
    return request({
      method: 'POST',
      url: '/cost/findExpenseCostMain',
      data
    })
  },
  findBillsExpenseSubitemByRelvanceIds(data){//根据关联单据查询报销明细
    return request({
      method: 'POST',
      url: '/cost/findBillsExpenseSubitemByRelvanceIds',
      data
    })
  },



  //发票相关
  findCostBillsInvoiceByCondition(data) { //条件查询发票信息
    return request({
      method: 'POST',
      url: '/cost/findCostBillsInvoiceByCondition',
      data
    })
  },
  addCostBillsInvoice(data) { //添加发票信息
    return request({
      method: 'POST',
      url: '/cost/addCostBillsInvoice',
      data
    })
  },
  updateCostBillsInvoice(data) { //修改发票主表信息
    return request({
      method: 'POST',
      url: '/cost/updateCostBillsInvoice',
      data
    })
  },
  updateCostBillsInvoiceSubitem(data) { //修改发票关联表信息
    return request({
      method: 'POST',
      url: '/cost/updateCostBillsInvoiceSubitem',
      data
    })
  },
  deleteInvoice(data) { //作废发票信息
    return request({
      method: 'POST',
      url: '/cost/deleteInvoice',
      data
    })
  },
  updateInvoiceStatusNo(data) { //驳回发票信息
    return request({
      method: 'POST',
      url: '/cost/updateInvoiceStatusNo',
      data
    })
  },
  updateInvoiceStatusYes(data) { //通过发票信息
    return request({
      method: 'POST',
      url: '/cost/updateInvoiceStatusYes',
      data
    })
  },
  againstInvoiceBill(data) { //发票反审操作
    return request({
      method: 'POST',
      url: '/cost/againstInvoiceBill',
      data
    })
  },
  updateInvoiceSubmit(data) { //提交发票信息
    return request({
      method: 'POST',
      url: '/cost/updateInvoiceSubmit',
      data
    })
  },
  findInvoiceAndSubItemByIds(data) { //根据关联单据id查询开票信息
    return request({
      method: 'POST',
      url: '/cost/findInvoiceAndSubItemByIds',
      data
    })
  },
  findCostBillsInvoiceDetail(data) { //根据id查询发票单详情
    return request({
      method: 'POST',
      url: '/cost/findCostBillsInvoiceDetail',
      data
    })
  },
  addCostBillsExpenseExport(data) { //生成报销单单据
    return request({
      method: 'POST',
      url: '/orderCommon/addCostBillsExpenseExport',
      data
    })
  },
  exportCostExpenseList(data) { //报销单例表
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportCostExpenseList',
      data
    })
  },
  addCostBillsInvoiceExport(data){//生成发票单据
    return request({
      method:'POST',
      url:'/orderCommon/addCostBillsInvoiceExport',
      data
    })
  },
  exportCostInvoiceList(data) { //发票例表
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportCostInvoiceList',
      data
    })
  },
  addCostBillsMainExport(data){//生成付款单单据
    return request({
      method:'POST',
      url:'/orderCommon/addCostBillsMainExport',
      data
    })
  },
  exportCostBillsList(data) { //收付款单例表导出
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportCostBillsList',
      data
    })
  },


  //对账单相关
  findAccountCheckBillsByCondition(data) { //收付款单例表
    return request({
      method: 'POST',
      url: '/cost/findAccountCheckBillsByCondition',
      data
    })
  },
  findCostCheckTotalAmount(data){// 获取对账单总金额信息
    return request({
      method: 'POST',
      url: '/cost/findCostCheckTotalAmount',
      data
    })
  },
  findCostCheckBillsDetail(data){// 查询对账单详情
    return request({
      method: 'POST',
      url: '/cost/findCostCheckBillsDetail',
      data
    })
  },
  addAccountCheckBillsMain(data){//添加对账单信息
    return request({
      method: 'POST',
      url: '/cost/addAccountCheckBillsMain',
      data
    })
  },
  updateBillsCheck(data){//修改对账单基本信息
    return request({
      method: 'POST',
      url: '/cost/updateBillsCheck',
      data
    })
  },
  updateBillsCheckSubItem(data){//修改对账单明细信息
    return request({
      method: 'POST',
      url: '/cost/updateBillsCheckSubItem',
      data
    })
  },
  updateCheckSubmit(data){//对账单提交审核
    return request({
      method: 'POST',
      url: '/cost/updateCheckSubmit',
      data
    })
  },
  updateCheckSubmitYes(data){//审核对账单
    return request({
      method: 'POST',
      url: '/cost/updateCheckSubmitYes',
      data
    })
  },
  updateCheckSubmitNo(data){//驳回对账单
    return request({
      method: 'POST',
      url: '/cost/updateCheckSubmitNo',
      data
    })
  },
  updateBillSubmitCheck(data){//对账确认
    return request({
      method: 'POST',
      url: '/cost/updateBillSubmitCheck',
      data
    })
  },
  deleteCostCheckBillsMain(data){//作废对账单
    return request({
      method: 'POST',
      url: '/cost/deleteCostCheckBillsMain',
      data
    })
  },
  exportCostCheckList(data){//对账单生成单据列表
    return request({
      method:'POST',
      url:'/orderCommon/billsExport/exportCostCheckList',
      data
    })
  },
   addCostBillsCheckExport(data){//对账单生成单据列表
      return request({
        method:'POST',
        url:'/orderCommon/addCostBillsCheckExport',
        data
      })
    },
    
    generateExpenseDocument(data){//生产详细生成费用报销单
       return request({
         method:'POST',
         url:'/cost/generateExpenseDocument',
         data
       })
     },
     queryingReimbursement(data){//根据生产单id查询相应报销单
        return request({
          method:'POST',
          url:'/cost/queryingReimbursement',
          data
        })
      },
      delectMesExpend(data){//生产详细删除报销单
         return request({
           method:'POST',
           url:'/cost/delectMesExpend',
           data
         })
       },
    
}
