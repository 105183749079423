<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：关联单据选择组件
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="List">
    <el-dialog title="关联单据选择" :visible.sync="show_billingBox" top="5vh" :before-close="cancel_billing" width="85%"
      append-to-body :modal-append-to-body="false">
      <!-- 自定义头部 -->
      <div slot="title" class="billListCusHead">
        <!-- 头部标题 -->
        <span class="titleName">单据选择</span>

        <!-- 提示信息 -->
        <div class="tipBox">
          <el-tooltip placement="bottom-start">
            <div slot="content">
              <ul>
                <li>1.收付款模块选择单据时只加载未收/付款,收/付款中的单据。</li>
              </ul>
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
      </div>
      <!-- 列表总框 -->
      <div class="billing_dialog_table" id="billsTableBox">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div class="classfi_list">
            <ul>
              <li v-show="showSignArr.includes(0)" :class="{classfi_ItemSel:classifySign==0}" @click="getSaleBillList">
                销售</li>
              <li v-show="showSignArr.includes(3)" :class="{classfi_ItemSel:classifySign==3}"
                @click="getPurchaseBillList">采购</li>
              <li v-show="showSignArr.includes(2)" :class="{classfi_ItemSel:classifySign==2}" @click="getDepotBillList">
                库存</li>
              <li v-show="showSignArr.includes(1)" :class="{classfi_ItemSel:classifySign==1}"
                @click="getProductBillList">生产计划</li>
              <li v-show="showSignArr.includes(7)" :class="{classfi_ItemSel:classifySign==7}"
                @click="getProductPorcessBillList">生产工序</li>
              <li v-show="showSignArr.includes(6)" :class="{classfi_ItemSel:classifySign==6}"
                @click="getExpenseBillList">报销</li>
              <li v-show="showSignArr.includes(9)" :class="{classfi_ItemSel:classifySign==9}"
                @click="getCostCheckBillList">对账</li>
            </ul>
          </div>
        </div>

        <!-- 可拉伸框 -->
        <div class="list_handel"></div>

        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" v-model="searchValue" :placeholder="searchPlaceholder">
              <i class="el-icon-search" @click="getInitBillsData(classifySign)"></i>
            </div>
            <div class="operateSelect">
              <!-- 对账单单据状态-->
              <div class="operateItem" v-if="classifySign==9 && conditionObj.check_operate_type == 0">
                <span class="title">单据状态:</span>
                <el-select class="selectInput_120" v-model="selectedBillState" size="small" placeholder="请选择"
                  filterable>
                  <el-option value="-1" key="-1" label="全部" @click.native="getSelBillStateData('-1')">全部</el-option>
                  <el-option v-for="elem in costCheckState" :label="elem.name" :value="elem.value"
                    @click.native="getSelBillStateData(elem.value)">{{elem.name}}
                  </el-option>
                </el-select>
              </div>
              <div class="operateItem">
                <span class="title">制单人:</span>
                <el-select class="selectInput_150" v-model="selectedUserName" size="small" placeholder="请选择" filterable>
                  <el-option v-for="elem in personnelAllData" v-if="judgeShowUser(elem)" :key="elem.user_id"
                    :label="elem.user_name" :value="elem.user_name" @click.native="getSelUserData(elem)">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- 列表 -->
          <div class="right_list scollStyle">
            <!-- 表格(销售) -->
            <div class="right_list_table billing_table" v-show="classifySign==0">
              <el-table :data="billsTableData" @select="getCurrSelBills" @select-all="getAllSelBills" height="100%"
                border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容(销售模块) -->
                <el-table-column prop="saleOrderMain.billsNo" label="单据编号" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="span_pointer span_color_blue" v-if="scope.row.saleOrderMain"
                      @click="showNodeBillsBox(scope.row,classifySign,scope.row.saleOrderMain.isOrder)">{{scope.row.saleOrderMain.billsNo}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="saleOrderMain.billsDate" label="单据日期" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="" label="货品名称" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.salesProductInfoList && scope.row.salesProductInfoList.length > 0">
                      <!-- 一个货品时显示 -->
                      <span v-if="scope.row.salesProductInfoList.length == 1">
                        {{scope.row.salesProductInfoList[0].name}}
                      </span>

                      <!-- 多个货品时显示 -->
                      <div v-else>
                        <el-tooltip placement="right" effect="light">
                          <div slot="content">
                            <ul class="">
                              <li v-for="elem in scope.row.salesProductInfoList">
                                {{elem.name}}
                              </li>
                            </ul>
                          </div>
                          <span>有{{scope.row.salesProductInfoList.length}}个货品</span>
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="规格型号" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.salesProductInfoList && scope.row.salesProductInfoList.length > 0">
                      <!-- 一个货品时显示 -->
                      <span v-if="scope.row.salesProductInfoList.length == 1">
                        {{scope.row.salesProductInfoList[0].specifications}}
                      </span>

                      <!-- 多个货品时显示 -->
                      <div v-else>
                        <el-tooltip placement="right" effect="light">
                          <div slot="content">
                            <ul class="">
                              <li v-for="elem in scope.row.salesProductInfoList">
                                {{elem.specifications}}
                              </li>
                            </ul>
                          </div>
                          <span>有{{scope.row.salesProductInfoList.length}}个货品</span>
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="saleOrderMain.customer_name" label="客户" width="240" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="scope.row.saleOrderMain"
                      :title="scope.row.saleOrderMain.customerBillsName +':'+(!!scope.row.saleOrderMain.customerBills?scope.row.saleOrderMain.customerBills:'无')">{{scope.row.saleOrderMain.customer_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="saleOrderMain.customerBills" label="客户单据号" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="saleOrderMain.money" label="单据金额" width="120" v-if="saleSelType == 0"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.saleOrderMain">
                      <span v-if="scope.row.saleOrderMain.discount">{{scope.row.saleOrderMain.discount}}</span>
                      <span v-else>{{scope.row.saleOrderMain.money}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="billingTypeInfo.bills_type" label="单据类别" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
              </el-table>
            </div>

            <!-- 表格(库存) -->
            <div class="right_list_table billing_table" v-show="classifySign==2">
              <el-table ref="depotTable" :data="billsTableData" @select="getCurrSelBills" @select-all="getAllSelBills"
                height="100%" border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column type="selection" :selectable="disableDefaultRow" fixed width="45"></el-table-column>
                <!-- 数据内容(销售模块) -->
                <el-table-column prop="depotMain.billsNo" label="单据编号" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="span_pointer span_color_blue" v-if="scope.row.depotMain"
                      @click="showNodeBillsBox(scope.row,classifySign,0)">{{scope.row.depotMain.billsNo}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="depotMain.billsDate" label="单据日期" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="billingTypeInfo.bills_type" label="单据类别" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="customerName" label="客户名称" width="240" v-if="conditionObj.depot_classify == 1"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="supplier" label="供应商名称" width="240" v-if="conditionObj.depot_classify == 0"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="depotMain.relevanceBillsNo" label="源单单号" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="depotMain.totalPriceMoney" label="单据金额" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
              </el-table>
            </div>

            <!-- 表格(采购) -->
            <div class="right_list_table billing_table" v-show="classifySign==3">
              <el-table :data="billsTableData" @select="getCurrSelBills" @select-all="getAllSelBills" height="100%"
                border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容(销售模块) -->
                <el-table-column prop="purchaseOrderMain.billsNo" label="单据编号" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="span_pointer span_color_blue" v-if="scope.row.purchaseOrderMain"
                      @click="showNodeBillsBox(scope.row,classifySign,scope.row.purchaseOrderMain.purchaseClassify)">{{scope.row.purchaseOrderMain.billsNo}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="purchaseOrderMain.billsDate" label="单据日期" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="purchaseOrderMain.supplierName" label="供应商" width="240" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="purchaseOrderMain.money" label="单据金额" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="billingTypeInfo.bills_type" label="单据类别" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
              </el-table>
            </div>

            <!-- 表格(生产计划单) -->
            <div class="right_list_table billing_table" v-show="classifySign==1">
              <el-table :data="billsTableData" @select="getCurrSelBills" @select-all="getAllSelBills" height="100%"
                border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容(销售模块) -->
                <el-table-column prop="bills_no" label="单据编号" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="span_pointer span_color_blue" v-if="scope.row"
                      @click="showNodeBillsBox(scope.row,classifySign,0)">{{scope.row.bills_no}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="bills_date" label="单据日期" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="productInfo.product_code" label="货品编号" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="productInfo.name" label="货品名称" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="scope.row.productInfo"
                      :title="scope.row.productInfo.supplier_name">{{scope.row.productInfo.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="productInfo.specifications" label="货品规格" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="manufacture_type_name" label="生产类型" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="billingTypeInfo.bills_type" label="单据类别" width="120" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column prop="total_amount" label="单据金额" width="120"></el-table-column> -->
                <el-table-column prop="remark" label="备注" min-width="80" show-overflow-tooltip></el-table-column>
              </el-table>
            </div>

            <!-- 表格(报销) -->
            <div class="right_list_table billing_table" v-show="classifySign==6">
              <el-table :data="billsTableData" @select="getCurrSelBills" @select-all="getAllSelBills" height="100%"
                border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容(销售模块) -->
                <el-table-column prop="costBillsExpense.billsNo" label="单据编号" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="span_pointer span_color_blue" v-if="scope.row.costBillsExpense"
                      @click="showNodeBillsBox(scope.row,classifySign,scope.row.costBillsExpense.costClassify)">{{scope.row.costBillsExpense.billsNo}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="costBillsExpense.billsDate" label="单据日期" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="billingTypeInfo.bills_type" label="单据类别" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="costBillsExpense.totalAmount" label="单据金额" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="deptName" label="部门" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="userName" label="员工" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
              </el-table>
            </div>

            <!-- 表格(生产工序单) -->
            <div class="right_list_table billing_table" v-if="classifySign==7">
              <el-table :data="billsTableData" @select="getCurrSelBills" @select-all="getAllSelBills" height="100%"
                border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="mesMainNo" label="生产单号" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="billsNo" label="工序单号" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="span_pointer span_color_blue" v-if="scope.row"
                      @click="showNodeBillsBox(scope.row,classifySign,0)">{{scope.row.billsNo}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="billsNo" label="合并工序" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="scope.row.processType == 0">否</span>
                    <span v-if="scope.row.processType == 1" class="color_success">是</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="billsNo" label="单据日期" width="120" show-overflow-tooltip></el-table-column> -->
                <!-- <el-table-column prop="billingTypeInfo.bills_type" label="单据类别" width="150" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column prop="name" label="工序名称" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="货品编号" width="150" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.mesMainNumInfo && scope.row.mesMainNumInfo.length<=1">
                      {{scope.row.mesMainNumInfo[0].product_code}}
                    </div>
                    <div v-else>
                      <el-tooltip placement="right" effect="light">
                        <div slot="content">
                          <ul class="billsList_relvanceTips">
                            <li v-for="elem in scope.row.mesMainNumInfo">
                              {{elem.product_code}}
                            </li>
                          </ul>
                        </div>
                        <span v-if="scope.row.mesMainNumInfo">{{scope.row.mesMainNumInfo.length}}个编号</span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="货品名称" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.mesMainNumInfo && scope.row.mesMainNumInfo.length<=1">
                      {{scope.row.mesMainNumInfo[0].name}}
                    </div>
                    <div v-else>
                      <el-tooltip placement="right" effect="light">
                        <div slot="content">
                          <ul class="billsList_relvanceTips">
                            <li v-for="elem in scope.row.mesMainNumInfo">
                              {{elem.name}}
                            </li>
                          </ul>
                        </div>
                        <span v-if="scope.row.mesMainNumInfo">{{scope.row.mesMainNumInfo.length}}个名称</span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="货品规格" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.mesMainNumInfo && scope.row.mesMainNumInfo.length<=1">
                      {{scope.row.mesMainNumInfo[0].specifications}}
                    </div>
                    <div v-else>
                      <el-tooltip placement="right" effect="light">
                        <div slot="content">
                          <ul class="billsList_relvanceTips">
                            <li v-for="elem in scope.row.mesMainNumInfo">
                              {{elem.specifications}}
                            </li>
                          </ul>
                        </div>
                        <span v-if="scope.row.mesMainNumInfo">{{scope.row.mesMainNumInfo.length}}个规格</span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80" show-overflow-tooltip></el-table-column>
              </el-table>
            </div>

            <!-- 表格(对账) -->
            <div class="right_list_table billing_table" v-show="classifySign==9">
              <el-table :data="billsTableData" @select="getCurrSelBills" @select-all="getAllSelBills" height="100%"
                border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容(销售模块) -->
                <el-table-column prop="billsNo" label="单据编号" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="span_pointer span_color_blue" v-if="scope.row"
                      @click="showNodeBillsBox(scope.row,classifySign,scope.row.checkClassify)">{{scope.row.billsNo}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="对账周期" width="420" v-if="conditionObj.check_classify == 5"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="periodvalue">{{scope.row.customerName}}</span>
                    <span class="periodvalue">{{scope.row.periodStartTime}}</span>
                    <span class="periodvalue">-</span>
                    <span class="periodvalue">{{scope.row.periodEndTime}}</span>
                    <span class="periodvalue">区间对账单</span>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="对账周期" width="420" v-if="conditionObj.check_classify == 6"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="periodvalue">{{scope.row.supplierName}}</span>
                    <span class="periodvalue">{{scope.row.periodStartTime}}</span>
                    <span class="periodvalue">-</span>
                    <span class="periodvalue">{{scope.row.periodEndTime}}</span>
                    <span class="periodvalue">区间对账单</span>
                  </template>
                </el-table-column>
                <el-table-column prop="billsDate" label="单据日期" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="billingTypeInfo.bills_type" label="单据类别" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="totalAmount" label="单据金额" width="120" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column prop="alreadyAmount" label="已收金额" width="120" v-if="conditionObj.check_classify == 5" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="alreadyAmount" label="已付金额" width="120" v-if="conditionObj.check_classify == 6" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column prop="totalAmount" label="单据状态" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div>
                      <el-button v-if="scope.row.isSubmit == 0" type="info" size="mini">未提交</el-button>
                      <el-button v-if="scope.row.isSubmit == 1" type="warning" size="mini">待对账</el-button>
                      <el-button v-if="scope.row.isSubmit == 2" type="danger" size="mini">已驳回</el-button>
                      <el-button v-if="scope.row.isSubmit == 3" type="primary" size="mini">复审中</el-button>
                      <el-button v-if="scope.row.isSubmit == 4" type="primary" size="mini">对账中</el-button>
                      <el-button v-if="scope.row.isSubmit == 5" type="success" size="mini">已对账</el-button>
                      <el-button v-if="scope.row.isSubmit == 6" type="primary" size="mini">结款中</el-button>
                      <el-button v-if="scope.row.isSubmit == 7" type="success" size="mini">已结款</el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
              </el-table>
            </div>

            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalPage">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_billing">确认</el-button>
        <el-button size="mini" @click="cancel_billing">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :billsTypeSign="billsTypeSign"
      :updateDefaultData="updateDefaultData" :operateType="operateType" :generateBillsId="generateBillsId"
      :billsTypeDefaultCode="billsTypeDefaultCode" v-if="show_nodeBills"></nodeBills>

    <!-- <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%" append-to-body  :modal-append-to-body="false">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入后端接口
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import depotApi from '@/network/stock/stock.js'; //库存模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import productApi from '@/network/production/production.js'; //生产模块
  import costApi from '@/network/cost/account.js'; //资金模块

  // 导入组件

  export default {
    name: 'list_billing',
    props: {
      //节点分类标志(打开该弹框的模块)
      openBillsTypeSign: {
        type: Number,
        default: -1
      },
      //控制显示可引用的单据分类
      showSignArr: {
        type: Array,
        default () {
          return []
        }
      },
      //生产工序单是否合并
      processBillIsMerge: {
        type: Number,
        default: -1
      },
      //销售单据列表选择方式(1:生产计划按单生产选择)
      saleSelType: {
        type: Number,
        default: 0
      },
      //不可选数据id数组
      disableBillIdArr: {
        type: Array,
        default () {
          return []
        }
      },
      // 已选单据id数组(不禁用)
      checkedBillIdArr: {
        type: Array,
        default () {
          return []
        }
      },
      //条件查询数据
      conditionObj: {
        type: Object,
        default () {
          return {
            //公共条件
            customer_id: -1, //客户id
            supplierId: -1, //供应商id
            //销售单据条件
            is_sale_submit: -1, //单据状态
            is_order: -1, //是否订单(销售订单引入报价单时可以引入报价单,其他只能引入订单)
            setlement_id: -1, //结算方式id
            currency_id: -1, //交易币种id
            out_depot_state: -1, //出库状态
            in_pay_state: -1, //收款状态
            out_receipt_state: -1, //发票状态
            order_purchase_state: -1, //订单采购状态
            order_mes_state: -1, //订单生产状态
            order_state: -1, //报价单生成订单状态
            // is_sale_pro:-1,//是否需要货品明细信息
            //出入库单状态
            depot_is_submit: -1, //出入库单状态
            depot_classify: -1, //出入库单分类
            depot_quality_state: -1, //质检状态
            depot_pay_state: -1, //付(收)款状态
            depot_depot_state: -1, //出入库状态
            depot_receipt_state: -1, //发票状态
            depot_bills_type: -1, //单据类型id
            depot_is_originbill: -1, //是否有源单
            depot_origin_sale_pay_state: -1, //源单收款状态
            depot_origin_purchase_pay_state: -1, //源单付款状态
            //采购单据条件
            is_purchase_submit: -1, //单据状态
            purchaseClassify: -1, //采购单分类
            in_depot_state: -1, //入库状态
            out_pay_state: -1, //付款状态
            in_receipt_state: -1, //发票状态
            purchase_state: -1, //采购申请单生成采购订单状态
            //生产计划单单据条件
            mes_is_submit: -1, //单据状态
            mes_in_depot_state: -1, //生产单成品入库状态
            mes_material_state: -1, //生产单领料状态
            mes_purchase_state: -1, //生产单采购状态
            //报销单条件
            expense_is_submit: -1, //单据状态
            expense_out_sign: -1, //收支标识
            expense_dispose_state: -1, //单据金额处理状态
            expense_receipt_state: -1, //发票状态
            //生产执行单单据条件
            mes_process_is_submit: -1, //生产执行单单据状态
            material_state: -1, //领料状态
            mes_process_purchase_state: -1, //请购状态
            process_type: -1, //工序单据类型(判断是计划单工序还是合并工序)
            pay_state: -1, //付款状态
            is_merge: -1, //是否被合并
            //对账单条件
            check_operate_type: -1, //对账单引用类型(0:收付款  1:发票)
            check_classify: -1, //对账单分类
            check_receipt_state: -1, //发票状态
          }
        }
      }
    },
    data() {
      return {
        dialogVisible: true,
        //分类数据
        classifySign: -1, //分类标识(0:销售 1:生产 2:库存  3:采购)

        // 当前组件数据
        searchValue: '', //搜索框内容
        searchPlaceholder: '', //搜索框提示信息
        billsTableData: [], //单据列表数据
        selecteCurrBill: {}, //当前选择数据
        selecteBillData: [], //已选单据数据
        selectedBillState: 5, //当前选择的单据状态(对账单用,默认显示已对账)
        selectedUserName: '', //当前选择的制单人名称
        selectedUserId: '', //当前选择的制单人id
        personnelAllData: [], //当前企业所有员工(含全部)

        //传入公共单据子组件的数据
        nodeTypeCode: '', //节点模块分类code值
        billsTypeSign: 0, //单据分类标签(0代表销售)
        billsClassify: -1, //单据分类
        operateType: 0, //操作类型(0:新增 1:修改  2:查看)
        generateBillsId: -1, //需要生成其他单据的id
        billsTypeDefaultCode: '', //默认单据类型
        updateDefaultData: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },

        //控制开关
        show_nodeBills: false, //控制显示单据公共组件

        //分页信息
        currentPage: 1, //当前页
        pageSize: 20, //页面大小
        totalPage: 0, //总页数

        //loading框
        loading: "",
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        show_billingBox: state => state.commComponent.show_billingBox, //控制显示单据选择弹框组件
        customer_data: state => state.commComponent.customer_data, //单据所选客户信息
        personnelData: state => state.hr.personnelData, //当前企业所有员工
        costCheckState: state => state.bill_state.costCheckState, //对账状态
      }),
    },
    created() {},
    mounted() {
      this.$nextTick(() => {
        //获取企业员工
        this.mergePersonnelData();
      })
      //获取单据列表数据(默认获取销售单列表数据)
      // this.getInitBillsData(this.showSignArr[0]);
    },
    methods: {
      ...mapMutations([
        'SHOW_BILLINGBOX', //控制单据选择弹框是否显示
      ]),
      ...mapActions([
        'getAllUserData', //请求企业用户数据
      ]),

      /* 判断请求哪个接口 */
      getInitBillsData(sign) {
        if (sign == 0) { //销售
          this.getSaleBillList();
          this.searchPlaceholder = "请输入查询单据编号/客户名称/客户单据号";
        } else if (sign == 1) { //生产
          this.getProductBillList();
          this.searchPlaceholder = "请输入查询单据编号";
        } else if (sign == 2) { //库存
          this.getDepotBillList();
          this.searchPlaceholder = "请输入查询单据编号";
        } else if (sign == 3) { //采购
          this.getPurchaseBillList();
          this.searchPlaceholder = "请输入查询单据编号/供应商";
        } else if (sign == 6) { //报销
          this.getExpenseBillList();
          this.searchPlaceholder = "请输入查询单据编号";
        } else if (sign == 7) { //生产执行
          this.getProductPorcessBillList();
          this.searchPlaceholder = "请输入查询单据编号";
        } else if (sign == 9) { //对账
          this.getCostCheckBillList();
          this.searchPlaceholder = "请输入查询单据编号";
        }
      },

      /* 判断是否显示客户所属员工 */
      judgeShowUser(data) {
        //根据当前登录用户等级显示
        if (this.UserInfo.user_role == 1) { //系统管理员
          return true;
        } else if (this.UserInfo.user_role == 2) { //部门管理员
          if (data.dept_id == this.UserInfo.dept_id) {
            return true;
          }
        } else if (this.UserInfo.user_role == 3) { //普通员工
          if (this.UserInfo.user_id == data.user_id) {
            return true;
          }
        }
      },

      /* 拼接企业员工(加全部) */
      async mergePersonnelData() {
        //判断企业用户数据是否已获取
        if (!this.personnelData.length > 0) {
          await this.getAllUserData();
        }
        this.personnelAllData = JSON.parse(JSON.stringify(this.personnelData))
        let userAllObj = {
          user_id: -1,
          user_name: '全部'
        }
        //添加全部查询数据到最前面
        if (JSON.stringify(this.personnelAllData).indexOf(JSON.stringify(userAllObj)) == -1) {
          this.personnelAllData.unshift(userAllObj);
        }
        // 获取默认信息
        this.getDefaultData();
      },

      /* 获取默认信息*/
      getDefaultData() {
        this.selectedUserName = this.personnelAllData[0].user_name; //获取默认员工姓名
        this.selectedUserId = this.personnelAllData[0].user_id; //获取默认员工id
        this.getSelUserData(this.personnelAllData[0]); //默认查询全部
      },

      /* 获取当前选择的用户 */
      getSelUserData(data) {
        let selectedUserIds = []
        //判断是否为全部
        if (data.user_id == -1) { //为全部时不传制单人id(目前是传的,可优化)
          // this.selectedUserId = "";
          this.personnelData.forEach(item => {
            selectedUserIds.push(item.user_id)
          })
          //获取当前选择的用户id
          this.selectedUserId = selectedUserIds.join(',');
        } else {
          //获取当前选择的用户id
          this.selectedUserId = data.user_id;
        }
        //查询单据(初次查询时设置默认第一个)
        if (this.classifySign == -1) {
          this.classifySign = this.showSignArr[0];
        }
        this.getInitBillsData(this.classifySign);
      },

      /* 获取当前选择的单据状态 */
      getSelBillStateData(state) {
        //获取当前选中的单据状态
        this.selectedBillState = state;
        //触发查询方法(对账单)
        this.getCostCheckBillList();
      },

      /* 获取销售单据列表数据 */
      getSaleBillList() {
        //获取销售sign标识
        this.classifySign = 0;
        //定义单据加载公共条件
        let data = {
          //必要字段
          deleteFlag: 0, //删除标识
          pageIndex: this.currentPage, //当前页码
          pageSize: this.pageSize, //页面大小
          createUserId: this.selectedUserId, //制单人id
          isSaleProList: 1, //控制是否加载货品信息
          commSearchValue: this.searchValue, //搜索框内容搜索
        }
        //判断单据状态
        if (this.conditionObj.is_sale_submit != -1) {
          data.isSubmit = this.conditionObj.is_sale_submit;
        }
        //判断是否为订单条件
        if (this.conditionObj.is_order != -1) {
          data.isOrder = this.conditionObj.is_order;
        }
        //判断客户id条件
        if (this.conditionObj.customer_id != -1) {
          data.customerId = this.conditionObj.customer_id;
        }
        //判断结算方式条件
        if (this.conditionObj.setlement_id != -1) {
          data.setlement_id = this.conditionObj.setlement_id;
        }
        //判断交易币种条件
        if (this.conditionObj.currency_id != -1) {
          data.currency = this.conditionObj.currency_id;
        }
        //判断出库状态
        if (this.conditionObj.out_depot_state != -1) {
          data.depotState = this.conditionObj.out_depot_state;
        }
        //判断收款状态
        if (this.conditionObj.in_pay_state != -1) {
          data.payState = this.conditionObj.in_pay_state;
        }
        //判断发票状态
        if (this.conditionObj.out_receipt_state != -1) {
          data.receiptState = this.conditionObj.out_receipt_state;
        }
        //判断采购状态
        if (this.conditionObj.order_purchase_state != -1) {
          data.purchaseState = this.conditionObj.order_purchase_state;
        }
        //判断生产状态
        if (this.conditionObj.order_mes_state != -1) {
          data.mesState = this.conditionObj.order_mes_state;
        }
        //判断报价单转换订单状态
        if (this.conditionObj.order_state != -1) {
          data.orderState = this.conditionObj.order_state;
        }
        //判断是否需要已设bom
        if (this.conditionObj.proIsSetBom != -1) {
          data.proIsSetBom = this.conditionObj.proIsSetBom;
        }
        //判断是否需要销售单据货品明细信息
        // if(this.conditionObj.is_sale_pro){
        //   data.isSaleProList = this.conditionObj.is_sale_pro;
        // }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsTableBox", 4, '单据列表加载中,请稍候...');
        //发送请求
        sellApi.findSaleOrderMainByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表数据
            this.billsTableData = res.data.rows;
            //设置客户数据未空的显示数据
            this.billsTableData.forEach((item, index) => {
              if (!item.saleOrderMain.customerBills) {
                item.saleOrderMain.customerBills = "/";
              }
            })
            //获取总页数
            this.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取采购单列表 */
      getPurchaseBillList() {
        //获取采购sign标识
        this.classifySign = 3;
        //定义传入后端的数据
        let data = {
          deleteFlag: 0, //删除标识
          pageIndex: this.currentPage, //当前页下标
          pageSize: this.pageSize, //页面大小
          createUserId: this.selectedUserId, //制单人id
          commSearchValue: this.searchValue, //搜索框内容搜索
        }

        //判断单据状态
        if (this.conditionObj.is_purchase_submit != -1) {
          data.isSubmit = this.conditionObj.is_purchase_submit;
        }
        //判断是否有供应商id条件
        if (this.conditionObj.supplierId != -1) {
          data.supplierId = this.conditionObj.supplierId;
        }
        //判断是否有采购单据分类条件
        if (this.conditionObj.purchaseClassify != -1) {
          data.purchaseClassify = this.conditionObj.purchaseClassify;
        }
        //判断入库状态
        if (this.conditionObj.in_depot_state != -1) {
          data.depotState = this.conditionObj.in_depot_state;
        }
        //判断付款状态
        if (this.conditionObj.out_pay_state != -1) {
          data.payState = this.conditionObj.out_pay_state;
        }
        //判断采购发票状态
        if (this.conditionObj.in_receipt_state != -1) {
          data.receiptState = this.conditionObj.in_receipt_state;
        }
        //判断采购申请单生成订单状态
        if (this.conditionObj.purchase_state != -1) {
          data.purchaseState = this.conditionObj.purchase_state;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsTableBox", 4, '单据列表加载中,请稍候...');
        //发送请求
        purchaseApi.findPurchaseMainByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表数据
            this.billsTableData = res.data.rows;
            //获取总页数
            this.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取库存单据列表数据 */
      getDepotBillList() {
        //获取销售sign标识
        this.classifySign = 2;
        //定义传入后端的数据
        let data = {
          deleteFlag: 0, //删除标识
          pageIndex: this.currentPage, //当前页下标
          pageSize: this.pageSize, //页面大小
          createUserId: this.selectedUserId, //制单人id
          commSearchValue: this.searchValue, //搜索框内容搜索
        }

        //判断出入库单分类
        if (this.conditionObj.depot_classify != -1) {
          data.depotClassify = this.conditionObj.depot_classify;
        }
        // 判断出入库状态
        if (this.conditionObj.depot_is_submit != -1) {
          data.IsSubmit = this.conditionObj.depot_is_submit;
        }
        //判断出入库质检状态
        if (this.conditionObj.depot_quality_state != -1) {
          data.qualityState = this.conditionObj.depot_quality_state;
        }
        //判断出入库收付款状态
        if (this.conditionObj.depot_pay_state != -1) {
          data.payState = this.conditionObj.depot_pay_state;
        }
        //判断出入库单出入状态
        if (this.conditionObj.depot_depot_state != -1) {
          data.depotState = this.conditionObj.depot_depot_state;
        }
        //判断出入库单发票状态
        if (this.conditionObj.depot_receipt_state != -1) {
          data.receiptState = this.conditionObj.depot_receipt_state;
        }
        //判断出入库单单据类型
        if (this.conditionObj.depot_bills_type != -1) {
          data.billingTypeId = this.conditionObj.depot_bills_type;
        }
        //判断出入库单客户id
        if (this.conditionObj.customer_id != -1) {
          data.customerId = this.conditionObj.customer_id;
        }
        //判断出入库单供应商id
        if (this.conditionObj.supplierId != -1) {
          data.supplierId = this.conditionObj.supplierId;
        }
        //判断出入库单是否有源单
        if (this.conditionObj.depot_is_originbill != -1) {
          data.isOriginBill = this.conditionObj.depot_is_originbill;
        }
        //判断出入库单源单收款状态
        if (this.conditionObj.depot_origin_sale_pay_state != -1) {
          data.originSalePayState = this.conditionObj.depot_origin_sale_pay_state;
        }
        //判断出入库单源单付款状态
        if (this.conditionObj.depot_origin_purchase_pay_state != -1) {
          data.originPurchasePayState = this.conditionObj.depot_origin_purchase_pay_state;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsTableBox", 4, '单据列表加载中,请稍候...');
        //发送请求
        depotApi.findDepotMainByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表数据
            this.billsTableData = res.data.rows;
            //获取总页数
            this.totalPage = res.data.total;
            //处理不可选id数组
            this.$nextTick(() => {
              console.log(this.checkedBillIdArr);
              this.billsTableData.forEach((item, index) => {
                if (this.checkedBillIdArr.includes(item.depotMain.id + '')) {
                  this.$refs.depotTable.toggleRowSelection(item, true);
                }
              })
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取生产单据列表数据 */
      getProductBillList() {
        //获取生产sign标识
        this.classifySign = 1;
        //定义传入后端的数据
        let data = {
          deleteFlag: 0, //删除标识
          pageIndex: this.currentPage, //当前页下标
          pageSize: this.pageSize, //页面大小
          createUserId: this.selectedUserId, //制单人id
          commSearchValue: this.searchValue, //搜索框内容搜索
        }

        //判断单据状态
        if (this.conditionObj.mes_is_submit != -1) {
          data.isSubmit = this.conditionObj.mes_is_submit;
        }
        //判断领料状态
        if (this.conditionObj.mes_material_state != -1) {
          data.material_state = this.conditionObj.mes_material_state;
        }
        //判断入库状态
        if (this.conditionObj.mes_in_depot_state != -1) {
          data.depot_state = this.conditionObj.mes_in_depot_state;
        }
        //判断采购状态
        if (this.conditionObj.mes_purchase_state != -1) {
          data.purchase_state = this.conditionObj.mes_purchase_state
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsTableBox", 4, '单据列表加载中,请稍候...');
        //发送请求
        productApi.findMesMainByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表数据
            this.billsTableData = res.data.rows;
            //获取总页数
            this.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取生产工序单据列表数据 */
      getProductPorcessBillList() {
        //获取报销sign标识
        this.classifySign = 7;
        //定义传入后端的数据
        let data = {
          pageIndex: this.currentPage, //当前页下标
          pageSize: this.pageSize, //页面大小
          createUserId: this.selectedUserId, //制单人id
          commSearchValue: this.searchValue, //搜索框内容搜索
          // processType: 1, //值查询生产执行单主表数据
        }

        //判断单据状态
        if (this.conditionObj.mes_process_is_submit != -1) {
          data.isSubmit = this.conditionObj.mes_process_is_submit;
        }
        //判断是工序单数据还是生产单关联工序数据
        if (this.conditionObj.process_type != -1) {
          data.processType = this.conditionObj.process_type;
        }
        // 判断领料状态
        if (this.conditionObj.material_state != -1) {
          data.materialState = this.conditionObj.material_state;
        }
        // 判断采购状态
        if (this.conditionObj.mes_process_purchase_state != -1) {
          data.purchaseState = this.conditionObj.mes_process_purchase_state;
        }
        //判断付款状态
        if (this.conditionObj.pay_state != -1) {
          data.payState = this.conditionObj.pay_state;
        }
        //判断是否被合并
        if (this.conditionObj.is_merge != -1) {
          data.isMerge = this.conditionObj.is_merge;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsTableBox", 4, '单据列表加载中,请稍候...');
        //发送请求
        productApi.findMesProcessByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表数据
            this.billsTableData = res.data.rows;
            //获取总页数
            this.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取报销单据列表数据 */
      getExpenseBillList() {
        //获取报销sign标识
        this.classifySign = 6;
        //定义传入后端的数据
        let data = {
          deleteFlag: 0, //删除标识
          pageIndex: this.currentPage, //当前页下标
          pageSize: this.pageSize, //页面大小
          createUserId: this.selectedUserId, //制单人id
          commSearchValue: this.searchValue, //搜索框内容搜索
        }

        //判断有无单据状态条件
        if (this.conditionObj.expense_is_submit != -1) {
          data.isSubmit = this.conditionObj.expense_is_submit;
        }

        //判断单据金额处理状态
        if (this.conditionObj.expense_dispose_state != -1) {
          data.disposeState = this.conditionObj.expense_dispose_state;
        }

        //判断单据收支标识
        // if (this.conditionObj.expense_out_sign != -1) {
        //   data.disposeState = this.conditionObj.expense_out_sign;
        // }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsTableBox", 4, '单据列表加载中,请稍候...');
        //发送请求
        costApi.findBillsExpenseByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表数据
            this.billsTableData = res.data.rows;
            //获取总页数
            this.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取对账单列表信息 */
      getCostCheckBillList() {
        //获取报销sign标识
        this.classifySign = 9;
        //定义传入后端的数据
        let data = {
          deleteFlag: 0, //删除标识
          pageIndex: this.currentPage, //当前页下标
          pageSize: this.pageSize, //页面大小
          createUserId: this.selectedUserId, //制单人id
          commSearchValue: this.searchValue, //搜索框内容搜索
        }

        //判断对账单分类条件
        if (this.conditionObj.check_classify != -1) {
          data.costClassify = this.conditionObj.check_classify;
        }
        //判断客户id条件
        if (this.conditionObj.customer_id != -1) {
          data.customerId = this.conditionObj.customer_id;
        }
        //判断是否有供应商id条件
        if (this.conditionObj.supplierId != -1) {
          data.supplierId = this.conditionObj.supplierId;
        }
        //判断是否有单据状态条件(弹框选择)
        if (this.selectedBillState != -1) {
          data.isSubmit = this.selectedBillState;
        }
        //判断对账单发票状态条件
        if (this.conditionObj.check_receipt_state != -1) {
          data.receiptState = this.conditionObj.check_receipt_state;
        }
        //判断收付款选择还是发票选择
        if (this.conditionObj.check_operate_type == 1) { //发票
          data.isSubmit = "5,6,7";
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsTableBox", 4, '单据列表加载中,请稍候...');
        //发送请求
        costApi.findAccountCheckBillsByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取列表数据
            this.billsTableData = res.data.rows;
            //获取总页数
            this.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 禁用的不可选单据 */
      disableDefaultRow(row) {
        //判断模块
        if (this.classifySign == 2) { //库存
          return !this.disableBillIdArr.includes(row.depotMain.id);
        }
      },

      /* 获取选中的单据(selection:已选数据, row:当前选择数据) */
      getCurrSelBills(selection, row) {
        // 获取已选所有数据
        this.selecteBillData = selection;
        //获取当前选择数据
        this.selecteCurrBill = row;
      },

      /* 获取已选数据 */
      getAllSelBills(selection) {
        //获取所有已选数据
        this.selecteBillData = selection;
      },

      /* 分页页面大小发生改变 */
      handleSizeChange(val) {
        //获取分页大小
        this.pageSize = val;
        //获取数据
        this.getInitBillsData(this.classifySign);
      },

      /* 分页当前页发生改变 */
      handleCurrentChange(val) {
        //获取当前页
        this.currentPage = val;
        //获取数据
        this.getInitBillsData(this.classifySign);
      },

      /* 显示单据公共组件 */
      showNodeBillsBox(data, sign, classify) {
        this.$message({
          type: 'warning',
          message: '功能正在完善中,暂不支持预览!',
          duration: this.elDuration
        })
        return
        //判断是否有相应的权限
        if (this.commonJsExtend.judgeBillsSelPowerBySign(sign, classify)) {
          this.$message({
            type: 'warning',
            message: '管理员未给您开通相应查询权限,请确认!',
            duration: this.elDuration
          })
          return;
        }
        //公共数据
        //将操作设置为修改或预览
        this.operateType = 2;
        //设置默认单据类型
        this.billsTypeDefaultCode = data.billingTypeInfo.code;
        //设置单据分类类型
        this.billsClassify = classify;
        //设置单据分类code
        this.nodeTypeCode = this.commonJsExtend.findNodeTypeCodeBySign(sign);
        //设置单据类型标签(设置为对应模块)
        this.billsTypeSign = sign;

        //特殊数据
        if (sign == 0) { //销售
          //获取需要回显的单据id
          this.generateBillsId = data.id;
          //设置默认相关数据
          this.updateDefaultData = {
            nodeId: data.saleOrderMain.statusId, //节点id
            processId: data.saleOrderMain.processId, //流程id
          }
        } else if (sign == 1) { //生产计划
          //获取需要回显的单据id
          this.generateBillsId = data.id;
          //设置默认相关数据
          this.updateDefaultData = {
            nodeId: data.status_id, //节点id
            processId: data.process_id, //流程id
          }
        } else if (sign == 2) { //库存
          //获取需要回显的单据id
          this.generateBillsId = data.id;
          //设置默认相关数据
          this.updateDefaultData = {
            nodeId: data.depotMain.statusId, //节点id
            processId: data.depotMain.processId, //流程id
          }
        } else if (sign == 3) { //采购
          //获取需要回显的单据id
          this.generateBillsId = data.id;
          //设置默认相关数据
          this.updateDefaultData = {
            nodeId: data.purchaseOrderMain.statusId, //节点id
            processId: data.purchaseOrderMain.processId, //流程id
          }
        } else if (sign == 5) { //收付款

        } else if (sign == 6) { //报销
          //获取需要回显的单据id
          this.generateBillsId = data.id;
          //设置默认相关数据
          this.updateDefaultData = {
            nodeId: data.costBillsExpense.statusId, //节点id
            processId: data.costBillsExpense.processId, //流程id
          }
        } else if (sign == 7) { //生产执行

        } else if (sign == 8) { //发票

        } else if (sign == 9) { //对账
          //获取需要回显的单据id
          this.generateBillsId = data.id;
          //设置默认相关数据
          this.updateDefaultData = {
            nodeId: data.statusId, //节点id
            processId: data.processId, //流程id
          }
        }

        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 关闭单据公共弹框 */
      closeNodeBills() {
        this.show_nodeBills = false;
      },

      // 提交已选单据信息
      commit_billing() {
        //判断当前选择的单据模块
        if (this.classifySign == 9) { //对账模块
          //判断是否有选择单据
          if (this.selecteBillData.length == 0) { //未选
            this.$message({
              type: 'warning',
              message: '请选择对账单据!',
              duration: this.elDuration
            })
            return
          } else if (this.selecteBillData.length > 1) { //选多了
            this.$message({
              type: 'warning',
              message: '一次只能选择一张对账单!',
              duration: this.elDuration
            })
            return
          } else if (this.conditionObj.check_operate_type == 0) {//判断是否为收款单模块
            //判断对账单状态
            if ([1, 4].includes(this.selecteBillData[0].isSubmit)) {
              this.$message({
                type: 'warning',
                message: '待对帐/对账中,暂不能付款/收款,对帐确认完成后才可以 付款/收款',
                duration: this.elDuration
              })
              return
            } else if ([7].includes(this.selecteBillData[0].isSubmit)) {
              this.$message({
                type: 'warning',
                message: '本对账单已结款完成,无需再付款/收款',
                duration: this.elDuration
              })
              return
            } else if ([0, 1, 2, 3].includes(this.selecteBillData[0].isSubmit)) {
              this.$message({
                type: 'warning',
                message: '本对账单当前状态不支持对账,请先对账完毕再进行收付款!',
                duration: this.elDuration
              })
              return
            }
          }
        }

        //判断是否有选择单据
        if (this.selecteBillData.length == 0) { //未选
          this.$message({
            type: 'warning',
            message: '请至少选择一张单据!',
            duration: this.elDuration
          })
          return
        }

        //判断当前打开弹框的界面
        if (this.openBillsTypeSign == 1) { //生产计划
          //判断源单是否有多个货品
          if (this.selecteBillData[0].salesProductInfoList.length > 1) {
            this.$message({
              type: 'warning',
              message: '该销售单存在多个货品,无法以引入的方式生成生产计划单!',
              duration: this.elDuration
            })
            return
          }
        }

        //将数据传递到父组件
        this.$emit("SelectedData", this.selecteBillData, this.classifySign);
        this.SHOW_BILLINGBOX(false);
      },

      // 取消
      cancel_billing() {
        this.SHOW_BILLINGBOX(false);
        //调用父组件取消加载该组件方法(父组件中只要有不加载该组件的字段即可)
        this.$parent.cancleDialogOperate();
      }
    },
    components: {
      nodeBills: () => import('@/components/commComponent/billing/nodeBills'),
    }
  }
</script>

<style lang="less" scoped>
  .periodvalue {
    padding-left: 10px;
  }

  /* 自定义头部内容 */
  .billListCusHead {
    display:flex;
    align-items:center;
    // border:1px solid black;
    .titleName {
      font-size: 16px;
    }

    .tipBox {
      margin-left: 20px;
    }
  }
</style>
